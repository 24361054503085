import Turbolinks from 'turbolinks'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import 'alpinejs'

// Require images + css in webpack compilation
require.context('../images', true)
import '../css/application.scss'

Turbolinks.start()

document.addEventListener('turbolinks:load', function () {
  const childSwiper = new Swiper('#childSwiper', {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: '.nextSlide',
      prevEl: '.prevSlide',
    },
  })
})
